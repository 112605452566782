import React from "react";
import i18n from "../../config/i18n";
import { GiCargoCrane } from "react-icons/gi";
import { TbCrane } from "react-icons/tb";
import { FaBasketShopping, FaIndustry  } from "react-icons/fa6";
import '../styles/header/dropdown_menu__sub-element.scss'

export const SubMenu_SubElement = ({menuData, locale}) => {

    const iconArray = [
        <FaBasketShopping size={30} style={{color: "#F9C846"}} />, 
        <TbCrane size={30} style={{color: "#808c97"}} />, 
        <GiCargoCrane size={30} style={{color: "#5769A4"}} />,
        <FaIndustry size={30} style={{color: '#f96400'}} />,
        <img src="/images/monstock-icone-final-economie-circulaire.svg" style={{width: "30px", height: "30px", color: "#7CC242"}}/>
    ]

    return (
        <div className="subMenu">
            <div className="solutions">
                <div className="dropdown__subelement needs">
                    <h1>{menuData[0].subMenuTitle}</h1>
                    <ul>
                        {menuData[0].subMenuData.map((subMenuData, index) => (
                            <li key={index} onClick={() => {window.location.href = `/${i18n[locale].path}/${subMenuData.linkUrl}`}}>
                                <img src="/images/icon_besoins.svg"></img>
                                <a href={`/${i18n[locale].path}/${subMenuData.linkUrl}`} key={index}>{subMenuData.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="dropdown__subelement sectors">
                    <h1>{menuData[1].subMenuTitle.trim()}</h1>
                    {menuData[1].subMenuData.map((subMenuData, index) => (
                        <div className={`subdiv`} key={index} onClick={() => {window.location.href = `/${i18n[locale].path}/${subMenuData.linkUrl}`}}>
                            {iconArray[index]}
                            <a href={`/${i18n[locale].path}/${subMenuData.linkUrl}`}>{subMenuData.name}</a>
                        </div>
                    ))}
                </div>
            
                <div className={"side_subelement"}>
                    <ul>
                        {menuData.map((MenuData, index) => (
                            index === 2 || index === 3 || index === 4 ?
                                <li key={index} onClick={() => {window.location.href = `/${i18n[locale].path}/${MenuData.subMenuData[0].linkUrl}`}}>
                                    <a href={`/${i18n[locale].path}/${MenuData.subMenuData[0].linkUrl}`}>{MenuData.subMenuData[0].name}</a>
                                </li>
                            : null
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}