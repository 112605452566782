export const colors = {
  orange: "#fa6400",
  blue: "#5769A4",
  white: "#ffffff",
  seashellPeach: "#fff7ef",
  zircon: "#f0f5ff",
  lightgray: "#CDD3E4",

  orangeGradient: "linear-gradient(to left, #FD7543, #DE4e4E)",
  blueBg: "#EFF3FE",
  blueHr:  "rgba(109, 125, 176,.5)",
  blueLang: "#EBF2F8",
  blueLightSeven: "rgba(87, 105, 164,.7)",
  blueLightOne : "rgba(87, 105, 164,.1)",
  borderForm: "rgba(87, 105, 164, .25)",
}

export const fonts = {
  // font_primary: "Playfair Display",
}

const breakpoints = [768, 1023, 1215, 1408]
export const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
