// const i18n = require("../../config/i18n")
import browserLang from "browser-lang"
import Cookies from "js-cookie";
import { toUpper } from "lodash";

const locales = require("../../config/i18n")
export const NON_BREAKING_SPACE = '\u00a0';

export function getUserLanguage() {
  const defaultLocale = Object.values(locales).find(l => l.default === true);
  if (typeof window !== "undefined") {
    const userPreferedLang = window.sessionStorage.getItem("userPreferedLang")
    const browserUserLang = String(browserLang()).substring(0,2)
    if (!userPreferedLang) {
      const foundConfiguredLocale = Object.values(locales)
        .find(locale => browserUserLang === locale.siteLanguage);
      if (foundConfiguredLocale) {
        window.sessionStorage.setItem("userPreferedLang", browserUserLang)
        return foundConfiguredLocale.path
      } else {
        return defaultLocale.path
      }
    } else {
      const foundConfiguredLocale = Object.values(locales)
        .find(locale => userPreferedLang === locale.siteLanguage);
      if (foundConfiguredLocale) {
        return foundConfiguredLocale.path
      } else {
        return defaultLocale.path
      }
    }
  }
}

export function transformLocale({ locale, getOnlyFirstPart, separator = '_' }) {
  const splittedLocale = locale.split('-');
  if (getOnlyFirstPart) {
    return splittedLocale[0];
  }
  return `${splittedLocale[0]}_${toUpper(splittedLocale[1])}`;
}

function getLangPrefix(lang = '') {
  return lang.toLowerCase().slice(0, 2)
}

export function isExternalLink(link) {
  if (link === null) return false
  return link._linkType === "Link.web" ? true : false
}
export function isDocumentLink(link) {
  if (link === null) return false
  return link._linkType === "Link.document" ? true : false
}

export const buildRegisterURL = (url) => {
  let URL = url;
  let searchParams = '';
  if (Cookies.get('gclid')) {
    searchParams = searchParams !== ''
      ? `${searchParams}&gclid=${Cookies.get('gclid')}`
      : `gclid=${Cookies.get('gclid')}`;
  }

  if (Cookies.get('utm_source')) {
    searchParams = searchParams !== ''
      ? `${searchParams}&utm_source=${Cookies.get('utm_source')}`
      : `utm_source=${Cookies.get('utm_source')}`;
  }

  if (Cookies.get('utm_medium')) {
    searchParams = searchParams !== ''
      ? `${searchParams}&utm_medium=${Cookies.get('utm_medium')}`
      : `utm_medium=${Cookies.get('utm_medium')}`;
  }

  if (Cookies.get('utm_campaign')) {
    searchParams = searchParams !== ''
      ? `${searchParams}&utm_campaign=${Cookies.get('utm_campaign')}`
      : `utm_campaign=${Cookies.get('utm_campaign')}`;
  }

  if (Cookies.get('utm_term')) {
    searchParams = searchParams !== ''
      ? `${searchParams}&utm_term=${Cookies.get('utm_term')}`
      : `utm_term=${Cookies.get('utm_term')}`;
  }

  if (Cookies.get('utm_content')) {
    searchParams = searchParams !== ''
      ? `${searchParams}&utm_content=${Cookies.get('utm_content')}`
      : `utm_content=${Cookies.get('utm_content')}`;
  }
  URL = searchParams !== '' ? `${URL}?${searchParams}` : URL;
  return URL;
}

export function getFrontURL() {
  switch (process.env.GATSBY_ENV) {
    case 'development':
      return 'https://cloudzeta.monstock.net'
    case 'production':
      return 'https://cloud.monstock.net'
    default:
      return 'https://cloud.monstock.net'
  }
}

export const reverseStr = str => Array.from(str).reverse().join('');

const getIntegerAndDecimalPart = (number, dotChar) => String(number)
  .replace(/[.,]/g, dotChar)
  .split(dotChar);

export const addThousandsSeparator = (
  number,
  dot,
) => {
  const dotChar = dot ? '.' : ',';
  const [integerPart, decimalPart] = getIntegerAndDecimalPart(number, dotChar);
  const reversed = reverseStr(integerPart);
  let reversedResult = '';
  for (let i = 0; i < reversed.length; i++) {
    if (i > 0 && i % 3 === 0) {
      reversedResult += `${NON_BREAKING_SPACE}${reversed[i]}`;
    } else {
      reversedResult += reversed[i];
    }
  }
  if (decimalPart) {
    return `${reverseStr(reversedResult)}${dotChar}${decimalPart}`;
  }
  return reverseStr(reversedResult);
};

export const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
